import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import Head from 'next/head';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import VideoOverlay from './VideoOverlay';
import VideoControls from './VideoControls';

export interface VideoBlokProps extends SbBlokData {
  content: Asset;
  altText: string;
  information?: Array<SbBlokData>;
  poster?: Asset;
  preload?: '' | 'auto' | 'metadata';
  name?: string;
  description?: string;
  uploadDate?: string;
  thumbnail?: Array<Asset>;
}

interface VideoProps {
  blok: VideoBlokProps;
  className?: string;
}

const generateStructuredData = (videoData: VideoBlokProps) => {
  const {
    content: { filename },
    description = '',
    poster,
    name = '',
    uploadDate,
    thumbnail,
  } = videoData;

  const optimizedPoster =
    poster && poster.filename ? `${poster.filename}/m/` : undefined;

  const videoLdJsonObject = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name,
    description,
    thumbnailUrl:
      thumbnail?.map((item) => `${item.filename}/m/`) || optimizedPoster,
    uploadDate: new Date(
      uploadDate && uploadDate !== ''
        ? uploadDate.replace(/-/g, '/')
        : Date.now(),
    ).toISOString(),
    contentUrl: filename,
  };

  return (
    <script
      data-testid={`${videoData._uid}-structured-data`}
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(videoLdJsonObject),
      }}
    />
  );
};

const videoTypeFromFilename = (filename: string) => {
  const fileExtension = filename.split('.').reverse()[0];

  if (fileExtension.toLocaleLowerCase() === 'mov') {
    return 'video/mp4';
  }

  return `video/${fileExtension}`;
};

const Video = ({ blok, className }: VideoProps) => {
  const {
    content,
    preload = 'metadata',
    poster,
    information: [information] = [],
  } = blok;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isStarted, setIsStarted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const optimizedPoster =
    poster && poster.filename ? `${poster.filename}/m/` : undefined;

  const initializePlayer = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
      setIsMuted(videoRef.current.muted);
    }
  };

  const onPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
      setIsStarted(true);
    }
  };

  const onPause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleVideoClicked = () => {
    if (videoRef.current) {
      if (isPlaying) {
        onPause();
      } else {
        onPlay();
      }
    }
  };

  const handlePlaying = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setIsStarted(false);

    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.load();
    }
  };

  const handleMetaDataLoaded = () => initializePlayer();
  const handleCanPlay = () => initializePlayer();

  const handleLoadedData = () => {
    if (videoRef && videoRef.current && videoRef.current.readyState >= 2) {
      initializePlayer();
    }
  };

  const handleProgress = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleChangeTime = (time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const handleChangeVolume = (newVolume: number) => {
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
      setVolume(newVolume);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('playing', handlePlaying);
      videoRef.current.addEventListener('pause', handlePause);
      videoRef.current.addEventListener('loadedmetadata', handleMetaDataLoaded);
      videoRef.current.addEventListener('loadeddata', handleLoadedData);
      videoRef.current.addEventListener('ended', handleEnded);
      videoRef.current.addEventListener('canplay', handleCanPlay);
      videoRef.current.addEventListener('timeupdate', handleProgress);

      if (videoRef.current.readyState >= 2) {
        setDuration(videoRef.current.duration);
      }
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('playing', handlePlaying);
        videoRef.current.removeEventListener('pause', handlePause);
        videoRef.current.removeEventListener(
          'loadedmetadata',
          handleMetaDataLoaded,
        );
        videoRef.current.removeEventListener('loadeddata', handleLoadedData);
        videoRef.current.removeEventListener('ended', handleEnded);
        videoRef.current.removeEventListener('canplay', handleCanPlay);
        videoRef.current.removeEventListener('timeupdate', handleProgress);
      }
    };
  }, [videoRef]);

  return (
    <>
      <Head>{generateStructuredData(blok)}</Head>
      <div
        className={cx('flex flex-col gap-y-4 sm:gap-y-6 lg:gap-y-8', className)}
      >
        <div
          className={cx(
            'flex flex-col w-full h-full justify-center cursor-pointer',
            {
              'transform transition duration-300 hover:scale-[1.01]':
                !isPlaying,
            },
          )}
          {...storyblokEditable(blok)}
        >
          <div className="relative group">
            <video
              ref={videoRef}
              controls={false}
              playsInline
              preload={preload}
              className="rounded sm:rounded-lg object-cover h-full w-full object-center"
              poster={optimizedPoster}
            >
              {content.filename && (
                <source
                  src={content.filename}
                  type={videoTypeFromFilename(content.filename)}
                />
              )}
            </video>
            <div
              className={cx('absolute w-full h-full top-0 left-0', {
                'opacity-0': isStarted && isPlaying,
                'md:opacity-0 md:group-hover:opacity-100 transform transition duration-1000 cursor-pointer':
                  isStarted,
              })}
            >
              <VideoOverlay
                isStarted={isStarted}
                isPaused={isStarted && !isPlaying}
                onPlay={handleVideoClicked}
              />
            </div>
            <div
              className={cx(
                'absolute bottom-0 left-0 w-full p-2 pointer-events-none',
                {
                  'opacity-0':
                    (!isStarted && !isPlaying) || (isStarted && isPlaying),
                  'md:opacity-0 md:group-hover:opacity-100 transform transition duration-1000':
                    isStarted,
                },
              )}
            >
              <VideoControls
                duration={duration}
                currentTime={currentTime}
                onChangeTime={handleChangeTime}
                volume={volume}
                onChangeVolume={handleChangeVolume}
                onMuteUnmute={toggleMute}
                visible={isStarted}
                muted={isMuted}
              />
            </div>
          </div>
        </div>
        {information && <StoryblokComponent blok={information} />}
      </div>
    </>
  );
};

export default Video;
